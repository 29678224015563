import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';

const ReturnsSuccess = ({ location }) => {
	const [numParam, setNumParam] = useState(null);
	const [shipParam, setShipParam] = useState(null);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		setNumParam(params.get('confirmation_number'));
		setShipParam(params.get('ship'))
	}, []);

	return (
		<Layout>
			<Seo title="Returns Success" />
			<div className="o-row-4p5">
				<section className="c-returns-hero">
					<div className="o-wrapper o-wrapper--small">
						<div className="o-row-2">
							<h1 className="c-returns-title c-returns-title--success">
								Your Return Has Been <span className="u-color-secondary">Confirmed!</span>
							</h1>
						</div>
						<img
							className="c-returns-box-success"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/returns/box.svg"
							alt="box"
							loading="lazy"
						/>
					</div>
				</section>
			</div>

			<div className="o-row-4p5">
				<div className="o-wrapper o-wrapper--small">
					<div className="o-row-2">
						<div className="c-returns-conf-box">
							<p className="c-returns-conf-box__copy-num u-desktop-hidden">
								Confirmation #: <strong>{numParam}</strong>
							</p>
							<img
								className={shipParam === 'pickup' ? 'c-returns-conf-box__truck' : 'c-returns-conf-box__box'}
								alt=""
								loading="lazy"
								src={
									shipParam === 'pickup'
										? 'https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/returns/truck.svg'
										: 'https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/returns/ArchiveBox.svg'
								}
							/>
							<div className="c-returns-conf-box__copy">
								<div className="o-row-2">
									<p className="c-returns-conf-box__copy-num u-mobile-hidden">
										Confirmation #: <strong>{numParam}</strong>
									</p>
								</div>
								<div className="o-row-2">
									<p className="c-returns-conf-box__copy-title">
										You have selected
										<br />
										<strong>{shipParam === 'pickup' ? 'UPS pick-up' : 'UPS dropoff'}</strong>
									</p>
								</div>
								<div className="o-row-2">
									<p className="c-returns-conf-box__copy-disc">
										<span style={{ display: shipParam === 'pickup' ? 'none' : '' }}>
											Please use return label provided and drop off your package at your local UPS store.
										</span>
										<span style={{ display: shipParam === 'pickup' ? '' : 'none' }}>
											hear.com will schedule a UPS pickup <strong className="u-text-italic">via the hearo app</strong>.
											Pickup is automatically scheduled <strong className="u-text-italic">the next business day</strong>{' '}
											– acceptable to leave package on doorstep.
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="o-row">
						<div className="c-returns-conf-box c-returns-conf-box--alt">
							<div className="c-returns-conf-box--header">
								<h2 className="c-returns-conf-box--header-title">
									Here is a list of all <strong>the (7) items</strong> that need to be returned:
								</h2>
							</div>
							<div className="c-returns-items-row">
								<div className="c-returns-items-box-alt">
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/returns/ship-box.jpg"
										alt=""
										loading="lazy"
										className="o-fluid-img"
									/>
								</div>
								<div className="c-returns-items-col">
									<div className="c-returns-items-box">
										<div className="c-returns-items-box-num">1</div>
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/returns/hearing-aids.jpg"
											alt=""
											loading="lazy"
											className="o-fluid-img"
										/>
									</div>
									<p className="c-returns-items-col__title">hearing aids</p>
								</div>
							</div>
							<div className="c-returns-items-row">
								<div className="c-returns-items-col">
									<div className="c-returns-items-box">
										<div className="c-returns-items-box-num">2</div>
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/returns/noahlink.jpg"
											alt=""
											loading="lazy"
											className="o-fluid-img"
										/>
									</div>
									<p className="c-returns-items-col__title">NoahLink</p>
								</div>
								<div className="c-returns-items-col">
									<div className="c-returns-items-box">
										<div className="c-returns-items-box-num">3</div>
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/returns/usbhub.jpg"
											alt=""
											loading="lazy"
											className="o-fluid-img"
										/>
									</div>
									<p className="c-returns-items-col__title">USB Hub</p>
								</div>
								<div className="c-returns-items-col">
									<div className="c-returns-items-box">
										<div className="c-returns-items-box-num">4</div>
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/returns/otoscope.jpg"
											alt=""
											loading="lazy"
											className="o-fluid-img"
										/>
									</div>
									<p className="c-returns-items-col__title">Otoscope</p>
								</div>
							</div>
							<div className="c-returns-items-row">
								<div className="c-returns-items-col">
									<div className="c-returns-items-box">
										<div className="c-returns-items-box-num">5</div>
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/returns/cable.jpg"
											alt=""
											loading="lazy"
											className="o-fluid-img"
										/>
									</div>
									<p className="c-returns-items-col__title">charging cable</p>
								</div>
								<div className="c-returns-items-col">
									<div className="c-returns-items-box">
										<div className="c-returns-items-box-num">6</div>
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/returns/tablet.jpg"
											alt=""
											loading="lazy"
											className="o-fluid-img"
										/>
									</div>
									<p className="c-returns-items-col__title">tablet</p>
								</div>
								<div className="c-returns-items-col">
									<div className="c-returns-items-box">
										<div className="c-returns-items-box-num">7</div>
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/returns/receivers.jpg"
											alt=""
											loading="lazy"
											className="o-fluid-img"
										/>
									</div>
									<p className="c-returns-items-col__title">receivers</p>
								</div>
							</div>
							<div className="c-returns-conf-box--footer">
								<p className="c-returns-conf-box--footer-copy">
									Please contact <span className="u-inline-block">(888) 780-3450</span> for questions.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ReturnsSuccess;
